import { FC } from "react";
import NoSSRWrapper from "@/helpers/NoSSRWrapper";
import * as Styled from "./homeTitle.style";

interface HomeTitleProps {
  children: React.ReactNode;
  sx?: {};
  color?: string;
  bgColor?: string;
  mb?: string;
  borderColor?: string;
  className?: string;
}

export const HomeTitle: FC<HomeTitleProps> = ({
  children,
  sx,
  color,
  bgColor,
  mb,
  borderColor,
  className,
}) => {
  return (
    <NoSSRWrapper>
      <Styled.H2
        sx={sx}
        color={color}
        bgColor={bgColor}
        mb={mb}
        borderColor={borderColor}
        className={className}
      >
        {children}
      </Styled.H2>
    </NoSSRWrapper>
  );
};
