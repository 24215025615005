import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertMessage } from "../alert/AlertMessage";
import { ButtonMui } from "./ButtonMui";
import * as Styled from "./button.style";
import NoSSRWrapper from "@/helpers/NoSSRWrapper";

interface ButtonAlertProps {
  children: React.ReactNode;
  isLoading: boolean;
  onClick?: any;
  alertState: string;
  type: "submit" | "button";
  color?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  sx?: any;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  sxBtnMui?: any;
  loadingColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
}

export const ButtonAlert: FC<ButtonAlertProps> = ({
  isLoading,
  onClick,
  alertState,
  children,
  type,
  color,
  size,
  sxBtnMui,
  sx,
  endIcon,
  disabled,
  loadingColor,
}) => {
  return (
    <NoSSRWrapper>
      <Styled.BtnInfo sx={sx}>
        <ButtonMui
          disabled={disabled}
          size={size}
          type={type}
          sx={sxBtnMui}
          color={color}
          variant="contained"
          className="animate__animated animate__fadeIn"
          endIcon={
            isLoading ? (
              <CircularProgress color={loadingColor} size={20} />
            ) : endIcon ? (
              endIcon
            ) : null
          }
          onClick={onClick}
        >
          {children}
        </ButtonMui>
        <AlertMessage alertState={alertState} />
      </Styled.BtnInfo>
    </NoSSRWrapper>
  );
};
