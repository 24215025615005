import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import * as Styled from "./alert.styles";
import { Typography } from "@mui/material";

export const AlertMessage = ({ alertState }: { alertState: string }) => {
  const menssage = {
    success: "Your message was sent successfully",
    error: "An error occurred while sending the message",
    warning: "Please accept the terms and conditions",
    info: "An error occurred while sending the message",
    checkbox: "Please accept the terms and conditions",
  }[alertState];

  return (
    <Styled.AlertContainer>
      <span>
        {alertState === "success" && (
          <CheckCircleOutlineIcon sx={{ color: "success.main", mr: 1 }} />
        )}
        {alertState === "error" && (
          <ErrorOutlineIcon sx={{ color: "error.main", mr: 1 }} />
        )}
        {alertState === "warning" && (
          <CancelOutlinedIcon sx={{ color: "warning.main", mr: 1 }} />
        )}
        {alertState === "info" && (
          <ContactSupportOutlinedIcon sx={{ color: "info.main", mr: 1 }} />
        )}
        {alertState === "checkbox" && (
          <ErrorOutlineIcon sx={{ color: "warning.main", mr: 1 }} />
        )}
      </span>
      <Typography color="white">{menssage}</Typography>
    </Styled.AlertContainer>
  );
};
