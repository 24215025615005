const ValidateCaptcha = async (gReCaptchaToken: any) => {
  const res = await fetch("/api/tryCaptcha", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gRecaptchaToken: gReCaptchaToken,
    }),
  });
  const response = await res.json();
  if (response?.status === "success") {
    return { status: "true" };
  } else {
    return { status: "false" };
  }
};

export const SendFormWithCaptcha = async (executeRecaptcha: any) => {
  if (!executeRecaptcha) {
    return;
  }
  const gReCaptchaToken = await executeRecaptcha("enquiryFormSubmit");

  const status = await ValidateCaptcha(gReCaptchaToken);
  return status;
};
