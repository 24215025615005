import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { SolutionsContainer } from "./utility.styles";
import { useTheme } from "@cellectenergy/cellect-react-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Image from "next/image";

const UtilityTabContainer = () => {
  const { t } = useTranslation("global");
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={{ xs: "4.5rem", md: "5.5rem", lg: "7rem" }}
      maxWidth={"1440px"}
      margin={"0 auto"}
    >
      <Grid container rowGap={4} columnSpacing={7}>
        {/* first row */}
        <Grid item lg={6} md={6} sm={6} xs={12} order={{ xs: 1, md: 0 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "1rem", md: "1.5rem" }}
            justifyContent={"center"}
            alignItems={"flex-start"}
            height={"100%"}
            width={"100%"}
          >
            <TextInfo
              title={t("solutions.utility.firstCol.title")}
              coloredTitle={"Cellect Platform"}
              subTitle={t("solutions.utility.firstCol.subTitle")}
              description={t("solutions.utility.firstCol.description")}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display={"flex"}
          justifyContent={{ xs: "center" }}
          order={{ xs: 0, md: 1 }}
        >
          <SolutionsContainer>
            <Image
              src={"/assets/solutions/cellectPlatform.webp"}
              alt="cellect platform"
              fill={true}
              loading="lazy"
            />
          </SolutionsContainer>
        </Grid>
      </Grid>

      {/* second row */}

      <Grid container columnSpacing={7} rowGap={4}>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display={"flex"}
          justifyContent={{ xs: "center" }}
          height={"100%"}
        >
          <SolutionsContainer>
            <Image
              src={"/assets/solutions/connections.webp"}
              alt="rack connection"
              fill={true}
              priority
            />
          </SolutionsContainer>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          order={{ xs: 0, md: 1 }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "1rem", md: "1.5rem" }}
            justifyContent={"center"}
            alignItems={"flex-start"}
            height={"100%"}
            width={"100%"}
          >
            <TextInfo
              title={t("solutions.utility.secondCol.title")}
              coloredTitle={"Cellect Box"}
              subTitle={t("solutions.utility.secondCol.subTitle")}
              description={t("solutions.utility.secondCol.description")}
            />
          </Box>
        </Grid>
      </Grid>

      {/* third row */}

      <Grid container rowGap={4} columnSpacing={7}>
        <Grid item lg={6} md={6} sm={6} xs={12} order={{ xs: 1, md: 0 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "1rem", md: "1.5rem" }}
            justifyContent={"center"}
            alignItems={"flex-start"}
            height={"100%"}
            width={"100%"}
          >
            <TextInfo
              title={t("solutions.utility.thirdCol.title")}
              coloredTitle={"Cellect Control"}
              subTitle={t("solutions.utility.thirdCol.subTitle")}
              description={t("solutions.utility.thirdCol.description")}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display={"flex"}
          justifyContent={{ xs: "center" }}
          height={"100%"}
          order={{ xs: 0, md: 1 }}
        >
          <SolutionsContainer>
            <Image
              src={"/assets/solutions/trading.webp"}
              alt="trading"
              fill={true}
            />
          </SolutionsContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UtilityTabContainer;

export const TextInfo = ({
  title,
  coloredTitle,
  subTitle,
  description,
}: {
  title: string;
  coloredTitle: string;
  subTitle: string;
  description: string;
}) => {
  const theme = useTheme();
  return (
    <>
      <Box pb={{ lg: "0.5rem" }}>
        <Typography
          variant="display-small"
          fontWeight={500}
          color={theme.palette.common.corporateColors.primary.white}
        >
          {title}
          <Typography
            variant="display-small"
            fontWeight={500}
            sx={{
              color: theme.palette.common.corporateColors.secondary.turquoise,
            }}
          >
            {coloredTitle}
          </Typography>
        </Typography>
      </Box>
      <Typography
        variant="headline-small"
        fontWeight={500}
        color={theme.palette.common.corporateColors.primary.white}
      >
        {subTitle}
      </Typography>
      <Typography
        variant="body-large"
        color={theme.palette.common.corporateColors.primary.white}
      >
        {description}
      </Typography>
    </>
  );
};
