import Link from "next/link";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import * as Styled from "./checkbox.styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";

interface CheckboxProps {
  id: string;
  text: string;
  textLink: string;
  link: string;
  checked: boolean;
  onChange: () => void;
  sx?: any;
  checkboxColor?: string;
}

export const CustomCheckbox = ({
  id,
  checked,
  onChange,
  text,
  link,
  textLink,
  sx,
  checkboxColor,
}: CheckboxProps) => {
  const { t } = useTranslation("global");
  const theme = useTheme();
  const customBreakpoints = useMediaQuery("(min-width: 390px)");

  return (
    <Styled.CheckboxContainer>
      <label htmlFor="checkbox-agreement" hidden />
      <Checkbox
        id={id}
        size="small"
        checked={checked}
        onChange={onChange}
        sx={{
          "& .MuiSvgIcon-root": {
            color: checkboxColor
              ? checkboxColor
              : theme.palette.common.corporateColors.primary.midNightBlue,
          },
          ...sx,
        }}
      />

      <label htmlFor="checkbox-agreement">
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"flex-start"}
          gap={"2px"}
          flexDirection={customBreakpoints ? "row" : "column"}
        >
          <Typography
            color={
              sx.color || theme.palette.common.corporateColors.primary.white
            }
            variant="label-large"
          >
            {t(text)}
          </Typography>
          <Typography
            color={
              sx.color || theme.palette.common.corporateColors.primary.white
            }
            variant="label-large"
            fontWeight={500}
          >
            <Link href={`${link}`} target="_blank">
              {t(textLink)}
            </Link>
          </Typography>
        </Box>
      </label>
    </Styled.CheckboxContainer>
  );
};
