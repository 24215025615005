import React, { FC } from "react";
import * as Styled from "./button.style";
import NoSSRWrapper from "@/helpers/NoSSRWrapper";

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  justify?: "left" | "center" | "right" | undefined;
  bgcolor?: string;
  customcolor?: string;
}
export const Button: FC<ButtonProps> = ({
  children,
  className,
  onClick,
  type,
  disabled,
  justify,
  bgcolor,
  customcolor,
}) => {
  return (
    <NoSSRWrapper>
      <Styled.CustomButton
        className={className}
        onClick={onClick}
        type={type}
        disabled={disabled}
        justify={justify}
        bgcolor={bgcolor}
        customcolor={customcolor}
      >
        {children}
      </Styled.CustomButton>
    </NoSSRWrapper>
  );
};
