import { FC, useState } from "react";
import { useTheme } from "@cellectenergy/cellect-react-components";
import { Box } from "@mui/material";

interface Props {
  selecteditem?: number;
}

const Partners = [
  { id: 1, name: "afk", url: "https://arendalsfossekompani.no/en" },
  { id: 2, name: "bves", url: "https://www.bves.de/" },
  { id: 3, name: "volue", url: "https://www.volue.com" },
  {
    id: 10,
    name: "greenFlexibility",
    url: "https://www.green-flexibility.com/",
  },
  { id: 4, name: "upc", url: "https://www.upc.edu/en" },
  { id: 6, name: "aepibal", url: "https://aepibal.org/" },
  { id: 9, name: "weidmuller", url: "https://www.weidmueller.com/" },
  { id: 8, name: "elli", url: "https://www.elli.eco/en/home " },
];

const AutoSlider: FC<Props> = () => {
  const theme = useTheme();
  const [isStopped, setIsStopped] = useState(false);

  const repeatCount = 2;
  const arrayPartners = Array(repeatCount).fill(Partners).flat();

  return (
    <Box
      width={"100%"}
      padding={{ xs: "2rem 0 5rem", md: "3rem 0 5rem", lg: "4.5rem 0 7.5rem" }}
    >
      <div
        className="slider"
        style={{
          backgroundColor:
            theme.palette.common.corporateColors.primary.midNightBlue,
        }}
        onMouseEnter={() => setIsStopped(true)}
        onMouseLeave={() => setIsStopped(false)}
      >
        <div className={`slide-track${isStopped ? " stopped" : ""}`}>
          {arrayPartners.map((partner, index) => (
            <div
              className="slide"
              key={index}
              onClick={() => window.open(partner.url, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                className="slideImg"
                style={{
                  transition: "all 1s ease-in-out",
                  width: "320px",
                  height: " 45px",
                }}
                src={`./assets/functioning/partners/partner${partner.id}.svg`}
                alt={partner.name}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default AutoSlider;
