import { Typography } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";

interface Props {
  mobile?: string;
  desktop?: string;
  padding?: string;
  twoRows?: boolean;
  sx?: any;
}

export const GooglePolicy = ({ sx }: Props) => {
  const theme = useTheme();

  return (
    <Typography
      color={sx?.color || theme.palette.common.corporateColors.primary.white}
      variant="body-small"
      gap={"0.2rem"}
      width={{ xs: "100%", sm: "100%", md: "86%", lg: "70%", xl: "60%" }}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "0.2rem",
      }}
    >
      <span
        style={{
          display: "inline",
          width: "fit-content",
        }}
      >
        This site is protected by reCAPTCHA and the{" "}
      </span>
      <span style={{ display: "inline" }}>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
          style={{ width: "fit-content" }}
        >
          Google Privacy Policy
        </a>
      </span>{" "}
      <span>and </span>
      <span style={{ display: "inline" }}>
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
          style={{ width: "fit-content", marginRight: "0.2rem" }}
        >
          Terms of Service
        </a>
      </span>{" "}
      <span>apply.</span>
    </Typography>
  );
};
