import { Input } from "@cellectenergy/cellect-react-components";
import { TextFieldProps, styled } from "@mui/material";
import { FC } from "react";

interface ColorProps {
  textcolor?: string;
  InputText?: string;
  backgroundcolor?: string;
  underlineColor?: string;
  labelColor?: string;
  borderColor?: string;
  borderfocuscolor?: string;
}

export const InputText = styled(Input)<ColorProps>(
  ({
    theme,
    backgroundcolor,
    textcolor,
    underlineColor,
    labelColor,
    borderfocuscolor,
    borderColor,
  }: any) => ({
    // base input
    "& .MuiInputBase-input": {
      padding: "10px 12px",
    },

    // FilledInput
    ".MuiFilledInput-root": {
      color: textcolor
        ? textcolor
        : theme.palette.common.corporateColors.primary.midNightBlue,
      backgroundColor: backgroundcolor
        ? backgroundcolor
        : theme.palette.common.corporateColors.primary.white,

      "&:hover": {
        backgroundColor: backgroundcolor
          ? backgroundcolor + " !important"
          : theme.palette.common.corporateColors.primary.white + " !important",

        borderColor: "none",
        "&:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: `2px solid ${
            underlineColor
              ? underlineColor
              : theme.palette.common.corporateColors.secondary.turquoise
          }`,
        },
      },

      "&.Mui-focused": {
        backgroundColor: backgroundcolor
          ? backgroundcolor
          : theme.palette.common.corporateColors.primary.white,
        "&:before": {
          borderColor: underlineColor
            ? underlineColor
            : theme.palette.common.corporateColors.secondary.turquoise,
        },
      },

      "&.MuiFilledInput-underline:after": {
        borderBottom: `2px solid ${
          underlineColor
            ? underlineColor
            : theme.palette.common.corporateColors.secondary.turquoise
        }`,
      },
    },
    // OutlinedInput
    ".MuiOutlinedInput-root": {
      color: textcolor
        ? textcolor
        : theme.palette.common.corporateColors.primary.midNightBlue,
      backgroundColor: backgroundcolor
        ? backgroundcolor
        : theme.palette.common.corporateColors.primary.white,

      "&:hover": {
        backgroundColor: backgroundcolor
          ? backgroundcolor + " !important"
          : theme.palette.common.corporateColors.primary.white + " !important",
        borderColor: "none",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: borderfocuscolor ? borderfocuscolor : "" + " !important",
        },
      },
    },

    //MuiFormLabel-root
    "& .MuiFormLabel-root": {
      color: labelColor ? labelColor : theme.palette.common.neutral[60],

      "&.Mui-focused": {
        color: labelColor ? labelColor : theme.palette.common.neutral[60],
      },
    },

    "&.Mui-disabled": {
      backgroundColor: backgroundcolor
        ? backgroundcolor
        : theme.palette.common.corporateColors.primary.white,
    },

    "& .MuiOutlinedInput-root fieldset": {
      borderColor: borderColor
        ? borderColor
        : theme.palette.common.corporateColors.tertiary.gray + " !important",
      "& .Mui-focused": {
        borderColor: borderColor
          ? borderColor
          : theme.palette.common.corporateColors.secondary.turquoise +
            " !important",
      },

      "&:hover": {
        borderColor: borderColor
          ? borderColor
          : theme.palette.common.corporateColors.secondary.turquoise +
            " !important",
      },
    },
  })
);

export const CustomInput: FC<TextFieldProps & ColorProps> = ({
  textcolor,
  backgroundcolor,
  underlineColor,
  labelColor,
  placeholder,
  ...rest
}) => {
  return (
    <InputText
      placeholder={placeholder}
      textcolor={textcolor}
      backgroundcolor={backgroundcolor}
      underlineColor={underlineColor}
      labelColor={labelColor}
      {...rest}
    />
  );
};
