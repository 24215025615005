export const sendContact = (data: any) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Requested-With", "XMLHttpRequest");
  return fetch("https://api.emailjs.com/api/v1.0/email/send", {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  // return fetch("https://api.emailjs.com/api/v1.0/email/send", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(data),
  // });
};

export const sendContactToNetlify = (data: any, locale: any) => {
  return fetch(`/${locale}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data as any).toString(),
  });
};
