export enum ROUTES {
  contact = "contact",
  legal = "legal",
  policy = "policy",
  privacy = "privacy",
  blog = "blog",
  thankYouCall = "thank-you-call",
  thankYouContact = "thank-you-contact",
  aboutUs = "about",
}

export enum BLOG_ROUTES {
  blog = "blog",
  Challenges_in_BESS = "/blog/improving-battery-performance-with-performance-warranty",
}
