import { FC, Suspense, lazy } from "react";
import Link from "next/link";
import * as Styled from "./menu.styles";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";

const PersonIcon = lazy(() => import("@mui/icons-material/Person"));

const MenuLarge: FC = () => {
  const themeColor = useTheme();

  return (
    <Styled.MenuLargeContainer>
      <Styled.SelectorContainer></Styled.SelectorContainer>

      <Styled.ButtonContainer>
        <Link href="/about">
          <MenuOption
            color={themeColor.palette.common.corporateColors.primary.white}
            hoverColor={themeColor.palette.shades.primary40.primary50}
            text="About Us"
          />
        </Link>
        <Link href="/blog">
          <MenuOption
            color={themeColor.palette.common.corporateColors.primary.white}
            hoverColor={themeColor.palette.shades.primary40.primary50}
            text="Blog"
          />
        </Link>
        <a
          href="https://careers.cellectenergy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuOption
            color={themeColor.palette.common.corporateColors.primary.white}
            hoverColor={themeColor.palette.shades.primary40.primary50}
            text="Careers"
          />
        </a>

        <Link href="/contact">
          <MenuOption
            color={themeColor.palette.common.corporateColors.primary.white}
            hoverColor={themeColor.palette.shades.primary40.primary50}
            text="Contact Us"
          />
        </Link>

        <Button
          size="medium"
          variant="text"
          component="a"
          href="https://app.cellectenergy.com/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: themeColor.palette.common.corporateColors.primary.white,
            "&:hover": {
              color:
                themeColor.palette.common.corporateColors.primary.midNightBlue,
            },
          }}
          startIcon={
            <Suspense
              fallback={<div style={{ width: "1.5rem", height: "1.5rem" }} />}
            >
              <PersonIcon
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            </Suspense>
          }
        >
          <Typography variant="body-medium" fontWeight={500}>
            Login
          </Typography>
        </Button>
      </Styled.ButtonContainer>
    </Styled.MenuLargeContainer>
  );
};

const MenuOption = ({
  color,
  hoverColor,
  text,
}: {
  color: string;
  hoverColor: string;
  text: string;
}) => {
  return (
    <Typography
      color={color}
      variant="body-medium"
      sx={{
        "&:hover": {
          transition: "color 0.3s",
          color: hoverColor,
        },
        cursor: "pointer",
      }}
    >
      {text}
    </Typography>
  );
};
export default MenuLarge;
