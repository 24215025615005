import { css } from "styled-components";

export const breakpoints = {
  mobile: "400px",
  mobile_landscape: "576px",
  tablet_portrait: "768px",
  tablet_landscape_s: "1024px",
  tablet_landscape: "1194px",
  desktop: "1194px",
  desktop_xl: "1512px",
  desktop_xxl: "1920px",
  desktop_HD: "2560px",
};

type cssParams = Parameters<typeof css>;
const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;

export const respondTo = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
}, {} as Record<keyof typeof breakpoints, Function>);
