import { FC, Suspense, memo, useEffect, useMemo, useState } from "react";
import { CellectLogo } from "../ui/logo";
import { useTheme } from "@cellectenergy/cellect-react-components";

const MoreVertIcon = dynamic(() => import("@mui/icons-material/MoreVert"));
const MenuMobile = dynamic(() => import("../ui/menu/MenuMobile"), {
  ssr: false,
});
const MenuLarge = dynamic(() => import("../ui/menu/MenuLarge"), {
  ssr: true,
});

import * as Styled from "./navbar.styles";
import dynamic from "next/dynamic";

const NavBar: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const MenuLargeMemo = memo(MenuLarge);
  const MenuMobileMemo = memo(MenuMobile);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const [scrollData, setScrollData] = useState({
    scrollY: 0,
    lastY: 0,
  });

  const showNavbar = useMemo(() => {
    return scrollData.scrollY === 0 || scrollData.scrollY < scrollData.lastY;
  }, [scrollData.scrollY, scrollData.lastY]);

  useEffect(() => {
    // Evitar el uso de `window` en el servidor
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        // Usar `requestAnimationFrame` para agrupar actualizaciones de estado
        requestAnimationFrame(() => {
          setScrollData({
            scrollY: window.scrollY,
            lastY: scrollData.scrollY,
          });
        });
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollData.scrollY]);

  return (
    <Styled.NavbarContainer shownavbar={showNavbar}>
      <Styled.NavbarBox>
        <CellectLogo
          height="30"
          width="110"
          color={theme.palette.common.corporateColors.primary.white}
        />
        <Styled.MenuLargeContainer>
          <MenuLargeMemo />
        </Styled.MenuLargeContainer>
        <Styled.MenuIconSmall onClick={handleOpenMenu}>
          <MoreVertIcon
            sx={{
              color: theme.palette.common.corporateColors.primary.white,
            }}
          />
          <Suspense fallback={null}>
            <MenuMobileMemo isOpen={isOpen} handleOpenMenu={handleOpenMenu} />
          </Suspense>
        </Styled.MenuIconSmall>
      </Styled.NavbarBox>
    </Styled.NavbarContainer>
  );
};

export default NavBar;
