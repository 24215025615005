import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MainContainer,
  VideoContainer,
  ArrowContainer,
} from "./newMain.styles";
import Image from "next/image";
import { ButtonMui } from "@/components/ui";
import Link from "next/link";

const NewMain = () => {
  const theme = useTheme();
  const { t } = useTranslation("global");

  return (
    <MainContainer>
      <VideoContainer>
        <video
          className="custom-video"
          muted
          loop
          preload="auto"
          webkit-playsinline="true"
          playsInline
          autoPlay
          poster="/energyStorage.webp"
          style={{
            objectPosition: "70% 50%",
          }}
        >
          {/* <source src="/energyStorage.webm" type="video/webm" /> */}
          <source src="/energyStorage.mp4" type="video/mp4" />
        </video>
      </VideoContainer>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-start"
        flexDirection={"column"}
        height={"100%"}
        maxWidth={theme.breakpoints.values.lg}
        margin={"0 auto"}
        padding={{ xs: "0 1rem 2rem", md: "0 1.5rem 2rem", lg: "0 2rem 3rem" }}
        gap={{ xs: "1rem", md: "2rem", gap: "2rem" }}
      >
        <Grid container>
          <Grid
            lg={6.5}
            md={7.6}
            xs={12}
            item
            position={"relative"}
            zIndex={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            gap={{ xs: "1rem", md: "1.5rem" }}
          >
            <Typography
              variant="display-medium"
              fontWeight={500}
              color={theme.palette.common.corporateColors.primary.white}
            >
              {t("mainHome.title")}
            </Typography>
            <Typography
              variant="title-large"
              fontWeight={400}
              color={theme.palette.common.corporateColors.primary.white}
            >
              {t("mainHome.description")}
            </Typography>
            <Link href="#contactUs" passHref>
              <ButtonMui
                endIcon={<ArrowForwardIcon fontSize="large" />}
                variant="contained"
                sx={{
                  color:
                    theme.palette.common.corporateColors.primary.midNightBlue,
                  backgroundColor:
                    theme.palette.common.corporateColors.secondary.turquoise,
                }}
              >
                {t("mainHome.freeTrial")}
              </ButtonMui>
            </Link>
          </Grid>
        </Grid>

        <ArrowContainer>
          <Image
            src="/assets/main/keyboard_arrow_down.svg"
            alt="arrow-down"
            width={0}
            height={0}
            onClick={() => {
              const element = document.getElementById("smart-software");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
            style={{
              width: "2.25rem",
              height: "2.25rem",
              zIndex: 1,
              cursor: "pointer",
            }}
            loading="lazy"
          />
        </ArrowContainer>
      </Box>
    </MainContainer>
  );
};

export default NewMain;
