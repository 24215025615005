export const BatterySvg = (props: any) => {
  const strokeColor = props.strokeColor ?? "#FDFCFC";
  return (
    <svg
      width={props.width ?? "83"}
      height={props.height ?? "101"}
      viewBox="0 0 83 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={props.className}
        x="1.11914"
        y="1.77148"
        width="80"
        height="98"
        rx="3"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        className={props.className}
        d="M67.4036 94.7194H6.52051V28.9098V6.28125H67.4036V94.7194Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4036 28.7979H6.52051"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4036 50.7559H6.52051"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4036 72.793H6.52051"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4082 12.3975H73.2654V23.2886H67.7362"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4082 34.3232H73.2654V45.2143H67.7362"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4082 56.3613H73.2654V67.2524H67.7362"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M67.4082 78.0469H73.2654V88.938H67.7362"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M13.4893 17.8428H24.1414"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M13.4893 39.9609H24.1414"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M13.4893 61.6943H24.1414"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M13.4893 83.541H24.1414"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M50.7881 83.668H61.4403"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M56.1143 89.145V78.2539"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M50.7881 61.7744H61.4403"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M56.1143 67.2524V56.3613"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M50.7881 39.9287H61.4403"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M56.1143 45.3907V34.5156"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M50.7881 17.8105H61.4403"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={props.className}
        d="M56.1143 23.2886V12.3975"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
