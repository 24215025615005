import styled from 'styled-components';


export const H2 = styled.h2<{ sx?: {} , borderColor?:string , color?: string , mb? : string , bgColor?:string }>`
${({ sx }) => sx }
    align-items: center;
    background-color:  ${({ bgColor }) => bgColor || "none"};
    border-left: ${({ borderColor }) => borderColor ? `1px solid ${borderColor}` : "1px solid var(--on-primary-container-light)"  };
    color: ${({ color }) => color || "var(--on-surface-light)"};
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${({ mb }) => mb || "1rem"};
    padding: 0.5rem 0.625rem;
`;

