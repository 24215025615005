import { useTheme } from "@cellectenergy/cellect-react-components";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import UtilityTabContainer from "./utility/utilityTabContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function Solutions() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.common.corporateColors.primary.midNightBlue,
        padding: {
          xs: "5rem 1rem 2.5rem",
          md: "5rem 1.5rem 2.5rem",
          lg: "7.5rem 2rem 3rem",
        },
      }}
    >
      <UtilityTabContainer />
    </Box>
  );
}
