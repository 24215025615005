import { Box } from "@mui/material";
import { styled } from "@mui/material";

export const MainContainer = styled(Box)(({ theme }) => ({
  height: "62.75rem",
  position: "relative",
  overflow: "hidden",

  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(0deg, #031F32 20%, rgba(3, 31, 50, 0.00) 39.95%)",
    zIndex: 1,

    [theme.breakpoints.up("md")]: {
      background:
        "linear-gradient(0deg, #031F32 20%, rgba(3, 31, 50, 0.00) 39.95%)",
    },
  },
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  overflow: "hidden",
}));

export const ArrowContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  position: "relative",
}));
