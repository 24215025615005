import { Box, Typography } from "@mui/material";
import { Button, useTheme } from "@cellectenergy/cellect-react-components";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import { ButtonMui } from "@/components/ui";
import Link from "next/link";

const YourQuestions = ({
  title,
  description,
  button,
}: {
  title: string;
  description: string;
  button: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "3rem 1rem", md: "3rem 1.5rem", lg: "3rem 2ren" },
        textAlign: "center",
        alignItems: "center",
        gap: { xs: 2, md: 4 },
        backgroundColor:
          theme.palette.common.corporateColors.secondary.turquoise,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={3}
      >
        <Typography
          variant="display-small"
          color={theme.palette.common.corporateColors.primary.midNightBlue}
          fontWeight={500}
          maxWidth={{ xs: "19rem", md: "40rem", lg: "60rem" }}
        >
          {title}
        </Typography>

        <Typography
          maxWidth={{ lg: "32rem", xl: "40rem" }}
          variant="title-large"
          color={theme.palette.common.corporateColors.primary.midNightBlue}
        >
          {description}
        </Typography>
      </Box>
      <Link href="/contact">
        <Button
          variant="contained"
          sx={{
            backgroundColor:
              theme.palette.common.corporateColors.primary.midNightBlue,
            color: theme.palette.common.corporateColors.secondary.turquoise,
            "&:hover": {
              backgroundColor:
                theme.palette.common.corporateColors.primary.midNightBlue,
            },
          }}
          endIcon={<ArrowOutwardIcon />}
        >
          {button}
        </Button>
      </Link>
    </Box>
  );
};

export default YourQuestions;
