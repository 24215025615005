import { Padding } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material";

export const SmartSoftwareTitleContainer = styled(Box)(({ theme }) => ({
  width: "22.375rem",

  [theme.breakpoints.up("md")]: {
    width: "60rem",
  },

  [theme.breakpoints.up("lg")]: {
    width: "55.55rem",
  },
}));

export const CardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  maxWidth: "1440px",
  margin: "0 auto",
  gap: "2rem",
  overflowX: "auto",
  flexWrap: "nowrap",
  width: "100%",
  padding: "0 1rem",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "@media (orientation: portrait)": {
    overflowX: "unset",
  },
  [theme.breakpoints.up("md")]: {
    gap: "2rem",
    padding: "0 1.5rem",
  },

  [theme.breakpoints.up("lg")]: {
    overflowX: "auto",
    flexDirection: "row",
    gap: "2rem",
    padding: "0 2rem",
  },
}));
