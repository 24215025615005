import { Box } from "@mui/material";
import { styled } from "@mui/material";

export const UtilityContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  gap: "2rem",
  height: "43.75rem",
  padding: "2rem 1rem 0",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "0 auto",
  maxWidth: "1440px",

  [theme.breakpoints.up("md")]: {
    height: "auto",
    padding: "2rem 1.5rem",
    justifyContent: "space-between",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "4rem 2rem",
    gap: "5rem",
  },
}));

export const QuoteImageWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "0.875rem",
  top: "-1.52956rem",
  width: "3.33681rem",
  height: "2.52956rem",
}));

export const LeftColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0rem 3rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1.5rem",
  flex: "1 0 0",
  alignSelf: "stretch",
  width: "auto",

  [theme.breakpoints.up("md")]: { width: "35.25rem" },

  [theme.breakpoints.up("lg")]: {
    width: "35.25rem",
  },
}));

export const SolutionsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "358px",
  height: "271px",
  objectFit: "cover",
  borderRadius: "1.5rem",
  zIndex: 1,
  overflow: "hidden",

  [theme.breakpoints.up("md")]: {
    width: "100%",
    height: "384px",
  },

  [theme.breakpoints.up("lg")]: {
    width: "100%",
    height: "480px",
  },
  img: {
    objectFit: "cover !important",
    objectPosition: "center",
  },
}));

// Second Row Components
export const SecondRow = styled(Box)(({ theme }) => ({
  width: "100%",
  flexDirection: "column-reverse",
  alignItems: "center",
  paddingTop: "2rem",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row",
    padding: "3rem 2rem 2rem 3rem",
  },
}));

// Third Row Components
export const ThirdRow = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  flexDirection: "column",
  padding: "2rem 1rem 1rem 2rem",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row",
    padding: "3rem 2rem 2rem 3rem",
  },
}));

export const TextColumnTR = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0rem 3rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1.5rem",
  flex: "1 0 0",
  alignSelf: "stretch",

  [theme.breakpoints.up("md")]: { width: "24.9375rem" },

  [theme.breakpoints.up("lg")]: {
    width: "35.25rem",
  },
}));
