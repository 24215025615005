import { Box, styled } from "@mui/material";

interface NavbarProps {
  shownavbar?: boolean;
}

export const NavbarContainer = styled("nav")<NavbarProps>(
  // shouldForwardProp: (prop) => prop !== 'shownavbar',
  ({ theme, shownavbar }) => ({
    transition: "transform 0.1s ease",
    transform: `translateY(${shownavbar ? "0" : "-100%"})`,
    display: "flex",
    position: "fixed",
    zIndex: 100,
    top: 0,
    width: "100%",
    backgroundColor: "rgba(3,31,50,0.6)",

    backdropFilter: "blur(10px)",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 0",
    borderBottom:
      "1px solid" + theme.palette.common.corporateColors.primary.white,

    [theme.breakpoints.up("sm")]: {
      padding: "1rem 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1rem 0",
    },
  })
);

export const NavbarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "1440px",
  margin: "0 auto",
  padding: "0 1rem",

  [theme.breakpoints.up("sm")]: {
    padding: "0 1.5rem",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "0 2rem",
  },
}));

export const LogoIcon = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "79.77px",
  height: "21.87px",

  [theme.breakpoints.up("sm")]: {
    width: "127.15px",
    height: "34.86px",
    alignSelf: "flex-start",
  },

  [theme.breakpoints.up("xl")]: {
    width: "150px",
    height: "41.25px",
  },
}));

export const MenuIconSmall = styled(Box)<{ onClick?: any }>(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const MenuStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    backgroundColor: "var(--surface-light)",
    color: "var(--primary-light)",
    mt: 1.5,

    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const MenuLargeContainer = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

// export const NavbarContainer = styled("nav")<{
//   shownavbar?: boolean;
//   theme?: string;
// }>`
//   display: flex;
//   position: sticky;
//   top: 0;
//   width: 100%;
//   background-color: ${({ shownavbar, theme }) =>
//     shownavbar && theme === "dark"
//       ? " rgb(0, 51, 82)"
//       : shownavbar && theme === "light"
//       ? "var(--background-light)"
//       : ""};
//   color: ${({ shownavbar, theme }) =>
//     shownavbar && theme === "dark"
//       ? "var(--background-light)"
//       : shownavbar && theme === "light"
//       ? "var(--on-surface-light)"
//       : "var(--on-surface-dark)"};
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.75rem 0 0.75rem 0;
//   transition: transform 0.1s ease;
//   transform: translateY(${({ shownavbar }) => (shownavbar ? "0" : "-100%")});
//   opacity: ${({ shownavbar }) => (shownavbar ? "1" : "0")};
//   backdrop-filter: ${({ shownavbar }) => (shownavbar ? "blur(10px)" : "none")};
//   z-index: 100;
//   border-bottom: ${({ theme }) =>
//     theme === "light"
//       ? "1px solid var(--on-surface-light)"
//       : theme === "dark"
//       ? "1px solid var(--on-surface-dark)"
//       : "1px solid var(--on-primary-light)"};
// `;
