import { respondTo } from "@/respondTo";
import Styled from "styled-components";

export const CheckboxContainer = Styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 0 !important;
    ${respondTo.desktop`
    padding-bottom: 0;
    `}
    `;
