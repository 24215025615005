import { Box } from "@mui/material";
import Link from "next/link";
import { FC } from "react";

export const CellectLogo = ({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) => {
  return (
    <Link href="/" aria-label="Home">
      <Box width={"146px"}>
        <svg
          width={width ? width : "146"}
          height={height ? height : "40"}
          viewBox="0 0 146 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.4005 13.2341C61.2038 11.6438 62.4525 10.3215 63.9944 9.42859C65.6521 8.50065 67.5278 8.03322 69.4271 8.07482C70.9235 8.05163 72.406 8.36352 73.7662 8.98762C75.0241 9.57027 76.1102 10.468 76.9191 11.5937C77.7436 12.7537 78.2761 14.0954 78.4713 15.5051H74.9701C74.6871 14.24 73.977 13.111 72.9593 12.3081C71.8996 11.5139 70.6007 11.1048 69.2772 11.1484C68.0468 11.1219 66.8372 11.4677 65.8068 12.1405C64.7974 12.8142 64.0042 13.7651 63.5226 14.8789C62.4642 17.4602 62.4642 20.3542 63.5226 22.9354C64.0001 24.0553 64.7939 25.0115 65.8068 25.687C66.8372 26.3598 68.0468 26.7057 69.2772 26.6792C70.5967 26.7232 71.8915 26.3139 72.946 25.5194C73.9553 24.7121 74.6585 23.5841 74.9392 22.3224H78.4405C78.2514 23.7369 77.7235 25.0849 76.9015 26.2515C76.1012 27.3671 75.0277 28.2585 73.7839 28.8399C72.4183 29.4668 70.9294 29.7787 69.4271 29.7527C67.5309 29.8018 65.6558 29.3452 63.9944 28.4298C62.4506 27.5474 61.2007 26.23 60.4005 24.6419C59.5669 22.8591 59.1348 20.915 59.1348 18.9468C59.1348 16.9787 59.5669 15.0346 60.4005 13.2518V13.2341Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M80.1065 17.5898C80.7114 16.4174 81.6425 15.4449 82.7875 14.7897C83.9898 14.1137 85.3508 13.7712 86.7298 13.7975C88.1076 13.7707 89.4708 14.0843 90.6985 14.7103C91.8265 15.3012 92.765 16.1984 93.406 17.2988C94.0882 18.495 94.4412 19.8507 94.4291 21.2278C94.4281 21.6454 94.3972 22.0625 94.3365 22.4757H82.5935V22.6565C82.6069 23.8534 83.0624 25.003 83.8723 25.8844C84.2755 26.287 84.7583 26.6009 85.2899 26.8059C85.8214 27.0109 86.39 27.1026 86.9591 27.075C87.8652 27.104 88.7583 26.853 89.5167 26.3562C90.2102 25.8761 90.6993 25.1542 90.8881 24.3322H94.1777C93.9178 25.8711 93.0979 27.2595 91.8759 28.2303C90.5459 29.2727 88.8903 29.8115 87.2016 29.7516C85.7255 29.7856 84.2647 29.4458 82.9551 28.7639C81.7701 28.1276 80.7968 27.159 80.155 25.977C79.4744 24.6938 79.1343 23.2575 79.1672 21.8054C79.1283 20.3443 79.4509 18.8962 80.1065 17.5898ZM91.0865 20.0812C91.0006 19.0494 90.5148 18.092 89.7328 17.4134C88.9201 16.7569 87.8972 16.4169 86.8533 16.4565C85.8391 16.435 84.8518 16.7829 84.0752 17.4355C83.6854 17.765 83.3647 18.1685 83.1316 18.6226C82.8985 19.0767 82.7576 19.5724 82.717 20.0812H91.0865Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M95.9141 10.4336H99.2037V29.5715H95.9141V10.4336Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M101.348 10.4336H104.637V29.5715H101.348V10.4336Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M107.087 17.5898C107.691 16.4165 108.623 15.4437 109.769 14.7897C110.971 14.1137 112.332 13.7712 113.711 13.7975C115.089 13.7707 116.452 14.0843 117.68 14.7103C118.808 15.3012 119.746 16.1984 120.387 17.2988C121.069 18.495 121.422 19.8507 121.41 21.2278C121.409 21.6454 121.378 22.0625 121.317 22.4757H109.57V22.6565C109.584 23.8534 110.039 25.003 110.849 25.8844C111.252 26.287 111.735 26.6009 112.266 26.8059C112.798 27.0109 113.367 27.1026 113.936 27.075C114.842 27.104 115.735 26.853 116.493 26.3562C117.187 25.8761 117.676 25.1542 117.865 24.3322H121.15C120.894 25.8713 120.075 27.2605 118.852 28.2303C117.522 29.2727 115.867 29.8115 114.178 29.7516C112.702 29.7856 111.241 29.4458 109.932 28.7639C108.747 28.1276 107.773 27.159 107.132 25.977C106.45 24.6942 106.108 23.2579 106.139 21.8054C106.103 20.3436 106.429 18.8955 107.087 17.5898ZM118.063 20.0812C117.977 19.0494 117.491 18.092 116.709 17.4134C115.897 16.7569 114.874 16.4169 113.83 16.4565C112.813 16.4363 111.824 16.7875 111.047 17.4443C110.658 17.7738 110.337 18.1773 110.104 18.6314C109.871 19.0855 109.73 19.5812 109.689 20.0901L118.063 20.0812Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M134.745 28.2136C133.361 29.2839 131.642 29.8292 129.894 29.7526C128.472 29.7814 127.067 29.4411 125.815 28.7648C124.649 28.1147 123.701 27.1344 123.09 25.947C122.432 24.6457 122.103 23.2026 122.133 21.7446C122.1 20.2941 122.436 18.8589 123.108 17.5731C123.735 16.3978 124.691 15.4309 125.859 14.7906C127.127 14.1097 128.548 13.7679 129.987 13.7984C131.719 13.7201 133.421 14.2662 134.784 15.3374C136.045 16.3996 136.869 17.8898 137.099 19.5222H133.717C133.53 18.6831 133.063 17.9324 132.394 17.3923C131.687 16.851 130.815 16.5706 129.925 16.5985C129.321 16.5796 128.72 16.6988 128.169 16.9472C127.618 17.1956 127.131 17.5665 126.746 18.0317C125.91 19.0836 125.482 20.4023 125.542 21.7446C125.481 23.102 125.908 24.4363 126.746 25.5061C127.448 26.3014 128.421 26.8073 129.475 26.9255C130.53 27.0437 131.591 26.7656 132.452 26.1455C133.134 25.5969 133.603 24.8259 133.775 23.9671H137.126C136.894 25.6316 136.044 27.1477 134.745 28.2136Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M138.486 10.4336H141.776V13.9834H145.551V16.8717H141.776V25.0031C141.729 25.464 141.859 25.9254 142.137 26.2952C142.31 26.4393 142.51 26.5474 142.725 26.6133C142.94 26.6792 143.166 26.7015 143.39 26.6788H145.886V29.5715H142.724C141.254 29.5715 140.178 29.2261 139.496 28.5353C138.814 27.8444 138.479 26.7788 138.491 25.3383L138.486 10.4336Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M20.0022 20.002L40 20.002V10.4286L20.0022 10.4286V20.002Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M20 29.5755L40.0023 19.958V30.3825L20 39.9999V29.5755Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M19.9978 19.998H0V29.5714H19.9978V19.998Z"
            fill={color ? color : "#E2E2E6"}
          />
          <path
            d="M19.9978 10.4244L0 20.0419V9.61748L19.9978 0V10.4244Z"
            fill={color ? color : "#E2E2E6"}
          />
        </svg>
      </Box>
    </Link>
  );
};
