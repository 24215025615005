import { ROUTES } from "@/constants/routes";
import TagManager from "react-gtm-module";

interface CustomerInfo {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  message: string;
}

interface HandleSubmitProps {
  customerInfo: CustomerInfo;
  setError: React.Dispatch<React.SetStateAction<any>>;
  setAlertState: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomerInfo: React.Dispatch<React.SetStateAction<CustomerInfo>>;
  SendFormWithCaptcha: any; // Define el tipo correctamente
  executeRecaptcha: any;
  sendContact: any; // Define el tipo correctamente
  router: any; // Define el tipo correctamente
  t: any;
  error: any;
}

export const handleSubmitChecks = ({
  customerInfo,
  setError,
  setAlertState,
  setIsLoading,
  // setShowAlert,
  setCustomerInfo,
  SendFormWithCaptcha,
  executeRecaptcha,
  sendContact,
  router,
  t,
  error,
}: HandleSubmitProps): void => {
  setIsLoading(true);
  //check email format
  const emailRegex = /\.[A-Za-z]{2,}$/;
  if (!emailRegex.test(customerInfo.email)) {
    setError({
      ...error,
      email: true,
    });
    setIsLoading(false);
    return;
  }

  //send form with captcha
  SendFormWithCaptcha(executeRecaptcha).then((res: any) => {
    TagManager.dataLayer({
      dataLayer: {
        category: "forms",
        event: "trial-form-submit",
        action: "trial-form-submit",
      },
    });
    const emailContent = `
        Hello ${customerInfo.firstName},
        Thank you for contacting us. We have received your message:
        ${customerInfo.message}
        Best regards,
        Your Company Name
      `;
    const data = {
      service_id: "service_tk4roz4",
      template_id: "template_gzs5oqg",
      user_id: "n2IOOPOiPZCuhSeej",
      template_params: {
        ...customerInfo,
        message: emailContent,
      },
    };

    //helper function to send form
    sendContact(data)
      .then((res: any) => {
        if (res.status === 200) {
          setAlertState("success");
          setCustomerInfo({
            firstName: "",
            lastName: "",
            country: "",
            email: "",
            message: "",
          });
          // router.push(ROUTES.thankYouContact);
        } else {
          setAlertState("error");
          setTimeout(() => {
            setAlertState("");
          }, 5000);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        setAlertState("error");
        setIsLoading(false);
        setTimeout(() => {
          setAlertState("");
        }, 5000);
      });
  });
};
