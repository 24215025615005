import { Box, Typography } from "@mui/material";
import { Button, useTheme } from "@cellectenergy/cellect-react-components";
import { CardContainer } from "./smartSoftware.styles";

import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import { InnovateSvg } from "./svg/InnovateSvg";
import { BatterySvg } from "./svg/BatterySvg";
import { DataSeguritySvg } from "./svg/DataSeguritySvg";
import { ScientificComputingSvg } from "./svg/ScientificComputingSvg";
import { UserExperienceSvg } from "./svg/UserExperienceSvg";
import { useDraggable } from "react-use-draggable-scroll";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BLOG_ROUTES, ROUTES } from "@/constants/routes";
import Link from "next/link";
const SmartSoftware = () => {
  const theme = useTheme();
  const { t } = useTranslation("global");

  const Cards = [
    {
      title: `${t("SmartSoftware.firstCard.title")}`,
      id: "Software-1",
      description: `${t("SmartSoftware.firstCard.description")}`,
      icon: <ScientificComputingSvg className="fill-effect" />,
      btn: "Explore our team’s expertise",
      link: ROUTES.aboutUs,
    },
    {
      title: `${t("SmartSoftware.secondCard.title")}`,
      id: "Hardware-2",
      description: `${t("SmartSoftware.secondCard.description")}`,
      icon: <BatterySvg />,
      btn: "Discover why Cellect is different",
      link: BLOG_ROUTES.Challenges_in_BESS,
    },

    {
      title: `${t("SmartSoftware.thirdCard.title")}`,
      id: "Integration-3",
      description: `${t("SmartSoftware.thirdCard.description")}`,
      icon: <DataSeguritySvg className="fill-effect" />,
      btn: "Read about our IT security measures",
      link: BLOG_ROUTES.blog,
    },
    {
      title: `${t("SmartSoftware.fourthCard.title")}`,
      id: "Software-4",
      description: `${t("SmartSoftware.fourthCard.description")}`,
      icon: <UserExperienceSvg className="fill-effect" />,
      btn: "See what asset managers say",
      link: BLOG_ROUTES.blog,
    },
    {
      title: `${t("SmartSoftware.fifthCard.title")}`,
      id: "Hardware-5",
      description: `${t("SmartSoftware.fifthCard.description")}`,
      icon: <InnovateSvg className="fill-effect" />,
      btn: "Explore our insights and resources",
      link: BLOG_ROUTES.blog,
    },
  ];

  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as any);
  return (
    <Box
      id="smart-software"
      display="flex"
      maxWidth={"1440px"}
      width={"100%"}
      position={"relative"}
      height={"23rem"}
      margin={"0 auto"}
    >
      <CardContainer
        ref={ref}
        {...events}
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "1.5rem",
          cursor: "grab",
          position: "absolute",
          alignContent: "flex-start",
          top: 0,
          left: 0,
        }}
      >
        {Cards.map((card) => {
          return (
            <Box
              key={card.id}
              display="flex"
              alignSelf={"flex-start"}
              flexDirection="column"
              justifyContent="flex-start"
              position="relative"
              flex="0 0 auto"
              width="369px"
              height={{ xs: "390px", md: "324px" }}
              border={`1px solid ${theme.palette.surface.onMain}`}
              borderRadius={4}
              padding={2.8}
              sx={{
                transition: {
                  md: "height 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                },
                "&:hover": {
                  md: {
                    height: "390px",
                    backgroundColor:
                      theme.palette.shades.secondary40.secondary30,
                  },
                },
                //stroke for all svg elements
                "&:hover svg path, &:hover svg rect": {
                  md: {
                    stroke: theme.palette.secondary.main,
                  },
                },
                //fill only for svg elements with class fill-effect
                "&:hover svg.fill-effect path": {
                  md: {
                    fill: theme.palette.secondary.main,
                  },
                },
              }}
              overflow={"hidden"}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems={"center"}
                gap={2}
                flex="0 0 auto"
                height={"280px"}
              >
                <Box display={"flex"} justifyContent={"center"}>
                  {card.icon}
                </Box>

                <Typography
                  variant={"title-large"}
                  fontWeight={500}
                  color={theme.palette.common.corporateColors.primary.white}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body-large"
                  color={theme.palette.common.corporateColors.primary.white}
                >
                  {card.description}
                </Typography>
              </Box>

              {/* Botón que solo aparece al hacer hover */}

              <Box padding={"1.25rem 0 0"} width={"100%"}>
                <Link href={card.link}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                    color="secondary"
                  >
                    {card.btn}
                  </Button>
                </Link>
              </Box>
            </Box>
          );
        })}
      </CardContainer>
    </Box>
  );
};

export default SmartSoftware;
