import { Box, styled } from "@mui/material";
// Mobile Menu

interface MenuMobileContainerProps {
  isopen: string;
}
export const MenuMobileContainer = styled(Box)<MenuMobileContainerProps>(
  ({ theme, isopen }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.common.corporateColors.primary.midNightBlue,
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.4s ease-in-out",
    transform: isopen === "true" ? "translateY(0)" : "translateY(-100%)",
    opacity: isopen === "true" ? 1 : 0,
  })
);
export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 1.25rem 1.25rem 2.5rem;
  gap: 2.5rem;
`;

export const SelectorsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  gap: 2.5rem;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 1.25rem;
`;

//Large Menu

export const MenuLargeContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
`;

export const SelectorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`;

export const LanguageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`;

// export const LanguageSelector = styled.h6<{
//   isActive?: boolean;
//   theme: string;
// }>`
//     font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
//     color:
//     cursor: pointer;
//     color: ${({ theme }) =>
//       theme === "dark" ? "var(--background-light)" : "var(--on-surface-light)"};
//     ${respondTo.tablet_landscape_s`
//     cursor: pointer;

//     `}
//     `;
