import React, { FC } from "react";
import * as Styled from "./buttonMui.styled";
import NoSSRWrapper from "@/helpers/NoSSRWrapper";

interface ButtonMuiProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained" | undefined;
  size?: "small" | "medium" | "large" | undefined;
  color?: "primary" | "secondary" | undefined;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  disableElevation?: boolean;
  sx?: any;
}

export const ButtonMui: FC<ButtonMuiProps> = ({
  children,
  className,
  onClick,
  type,
  disabled,
  variant,
  size,
  color,
  sx,
  startIcon,
  endIcon,
  fullWidth,
  disableElevation,
}) => {
  return (
    <NoSSRWrapper>
      <Styled.ButtonMuiStyled
        className={className}
        onClick={onClick}
        type={type}
        disabled={disabled}
        variant={variant}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        disableElevation={true}
        sx={sx}
        color={color}
      >
        {children}
      </Styled.ButtonMuiStyled>
    </NoSSRWrapper>
  );
};
