import { SvgIcon } from "@mui/material";
import { SVGProps } from "react";

export const DataSeguritySvg = (props: any) => {
  const strokeColor = props.strokeColor ?? "#FDFCFC";
  return (
    <svg
      className={props.className}
      width={props.width ?? "101"}
      height={props.height ?? "101"}
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={props.className}
        d="M53.2441 83.1465H3.24414C2.62227 83.1465 2.11914 82.6434 2.11914 82.0215V60.1465C2.11914 59.5246 2.62227 59.0215 3.24414 59.0215H53.2441V61.2746H4.36914V80.8965H53.2441V83.1465ZM53.2441 55.0215H3.24414C2.62227 55.0215 2.11914 54.5184 2.11914 53.8965V32.0215C2.11914 31.3996 2.62227 30.8965 3.24414 30.8965H81.3691C81.991 30.8965 82.4941 31.3996 82.4941 32.0215V41.3965H80.2441V33.1465H4.36914V52.7715H53.2441V55.0215ZM81.3691 26.8965H3.24414C2.62227 26.8965 2.11914 26.3934 2.11914 25.7715V3.89648C2.11914 3.27461 2.62227 2.77148 3.24414 2.77148H81.3691C81.991 2.77148 82.4941 3.27461 82.4941 3.89648V25.7715C82.4941 26.3934 81.991 26.8965 81.3691 26.8965ZM4.36914 24.6465H80.2441V5.02148H4.36914V24.6465ZM68.8691 46.084C67.1441 46.084 65.7441 44.684 65.7441 42.959C65.7441 41.234 67.1441 39.834 68.8691 39.834C70.5941 39.834 71.9941 41.234 71.9941 42.959C71.9941 44.684 70.5941 46.084 68.8691 46.084ZM71.9941 14.834C71.9941 13.109 70.5941 11.709 68.8691 11.709C67.1441 11.709 65.7441 13.109 65.7441 14.834C65.7441 16.559 67.1441 17.959 68.8691 17.959C70.5941 17.959 71.9941 16.559 71.9941 14.834ZM78.2441 98.7715C67.2848 98.7715 58.3691 89.8527 58.3691 78.8965V56.3246L78.2441 46.3871L98.1191 56.3246V78.8965C98.1191 89.8559 89.2035 98.7715 78.2441 98.7715ZM60.6191 57.7184V78.8965C60.6191 88.6152 68.5223 96.5215 78.2441 96.5215C87.966 96.5215 95.8691 88.6184 95.8691 78.8965V57.7184L78.2441 48.9027L60.6191 57.7184Z"
        fill={strokeColor}
      />
    </svg>
  );
};
