import React from "react";

import { useTranslation } from "react-i18next";

import dynamic from "next/dynamic";

const MainLayout = dynamic(() => import("@/layouts/MainLayout"), {
  ssr: true,
});

const NewMain = dynamic(() => import("@/components/home/newMain/NewMain"), {
  ssr: true,
});

const Quotes = dynamic(() => import("@/components/home/quotes/Quotes"), {
  ssr: false,
});
const YourQuestions = dynamic(
  () => import("@/components/home/yourQuestions/YourQuestionst"),
  { ssr: true }
);
const SmartSoftware = dynamic(
  () => import("@/components/home/smartSoftware/SmartSoftware"),
  { ssr: true }
);

const AutoSlider = dynamic(
  () => import("@/components/home/partnerSliders/AutoSlider"),
  { ssr: true }
);
const ContactUs = dynamic(
  () => import("@/components/home/contactUs/ContactUs"),
  {
    ssr: true,
  }
);

const Solutions = dynamic(
  () => import("@/components/home/solutions/Solutions"),
  {
    ssr: true,
  }
);

export default function Home() {
  const { t } = useTranslation("global");

  return (
    <MainLayout
      title="Cellect Energy"
      description="Our platform offers a universal solution for energy storage asset managers, providing a standard API for remote control which eliminates integration needs."
      navbarTheme="dark"
    >
      <NewMain />
      <AutoSlider />
      <SmartSoftware />
      <Solutions />
      <Quotes />
      <YourQuestions
        title={t("bannerTwo.title")}
        description={t("bannerTwo.text")}
        button={t("bannerTwo.bookACall")}
      />
      <ContactUs />
    </MainLayout>
  );
}
