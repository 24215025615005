import * as React from "react";
import { ThemeProvider } from "@cellectenergy/cellect-react-components";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./createEmotionCache";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const ThemeWrapper = (props: any) => {
  const { emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider themeMode="dark">
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default ThemeWrapper;
