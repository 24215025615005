import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material";

export const ContactUsContainer = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(180deg, #031F32 0%, rgba(3, 31, 50, 0.10) 100%), url(assets/contactUs/connectedBg.webp) lightgray 50% / cover no-repeat",
}));

export const LeftColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",

  [theme.breakpoints.up("md")]: {},

  [theme.breakpoints.up("lg")]: {
    width: "42rem",
  },
}));

export const RightColumn = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {},

  [theme.breakpoints.up("lg")]: {},
}));

export const ContactCard = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1.5rem",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "start",
  gap: "1rem",
  flex: "1 0 0",
  borderRadius: "1rem",
  border: `3px solid ${theme.palette.common.corporateColors.secondary.turquoise}`,
  background: "#EBECE6",
  backdropFilter: "blur(5px)",
  height: "100%",

  [theme.breakpoints.up("md")]: { width: "30rem" },

  [theme.breakpoints.up("lg")]: {
    width: "42rem",
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "106px",
  height: "106px",
}));

export const InputField = styled(TextField)(({ theme }) => ({
  borderRadius: "0.25rem",
  border: `1px solid ${theme.palette.common.corporateColors.secondary.tiaraGray}`,
  background: theme.palette.common.corporateColors.primary.white,
  color: theme.palette.common.corporateColors.primary.midNightBlue,
}));

export const InputText = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.common.corporateColors.secondary.tiaraGray}`,
}));

export const CustomForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
}));
