import Styled from "styled-components";
import { Button } from "@mui/material";

export const CustomButton = Styled(Button)<{
  justify?: string;
  bgcolor?: string;
  customcolor?: string;
}>`
    background-color: ${({ bgcolor }) =>
      bgcolor ? bgcolor : "var(--primary-dark)"};
    color: ${({ customcolor }) =>
      customcolor ? customcolor : "var(--on-primary-dark)"};
    margin : ${({ justify }) =>
      justify === "center"
        ? "auto 0"
        : justify === "left"
        ? "0 0 0 auto"
        : justify === "right"
        ? "0 auto 0 0"
        : ""};
    border-radius: 24px;

  '&:hover': {
    background-color: var(--primary-dark),
    opacity: 0.8,
  },
  
`;

export const BtnInfo = Styled.div<{ sx?: any }>`
  ${({ sx }) => sx}
    `;
